.loading-overlay{
    position: absolute;
    top: 50%;
    left: 50%;

    width: calc(70% - 7px);
    height: 80%;
    
    transform: translate(-50%,-50%);

    /* width: calc(100% - 7px); */
    /* height: 100%; */

    z-index: 20;

    background-color: var(--templateBackground);

    animation: overlay-animation 3s cubic-bezier(0.6,0.01,0.05,0.95) 0s both; 
    
    overflow: hidden;
    /* border: 0.5px solid black; */
}

.loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading-img-bg{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    object-fit: cover;
    background-color: var(--templateBackground);

    overflow: hidden;

    /* border: 0.5px solid black; */

    z-index: 20;
}

.loading-img-bg>img{
    width: 100%;
    height: 100%;
    display: block;
    
    overflow: hidden;

    /* filter: grayscale(1); */

    object-fit: cover;
}

.loading-content{
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);

    width: 100%;
    height: 10vh;

    display: flex;
    justify-content: center;
    align-items: center;
    
    /* border: 0.5px solid black; */

     /* animate,animate2 1.6s linear, */
    animation: animate2 1s cubic-bezier(0.6,0.01,0.05,0.95) 2s forwards; 
    /* animation-iteration-count: 1.5; */

    /* animation: animate2 1.6s ease-in-out 2.4s;  */
}

.loading-to-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    animation: animate 1.2s linear; 
    animation-iteration-count: 1.5;
}

.loading-to-nav::before{
    position: absolute;
    left: 50%;
    bottom: 0%;
    
    content: "";
    width: 20%;
    height: 5px;
    
    background-color: var(--textColour);
    /* transform-origin: center 50%; */
    transform: translateX(-50%);
    animation: line-animate 1s cubic-bezier(0.6,0.01,0.22,0.93) 2s forwards;
}

.loading-to-nav>h1{
    font-family: 'Bodoni Moda', serif;
    /* font-family: 'Playfair Display', serif; */
    font-weight: 600;
    font-size: 2rem;

    margin: 0 1rem;

    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

@keyframes overlay-animation{
    0%{
        width: 100%;
        height: 100%;
    }
    40%{
        width: 70%;
        height: 80%;
    }
    52%{
        width: 70%;
        height: 80%;
    }
    100%{
        width: calc(100%);
        height: 100%;
    }
}

@keyframes animate { 
    0% { 
        opacity: 0; 
    } 

    50% { 
        opacity: 0.7; 
    } 

    100% { 
        opacity: 0; 
    } 
}

@keyframes animate2{
    from{
        top:50%;
        transform: translate(-50%, -50%);
    }
    to{
        top:0%;
        transform: translate(-50%, 0%);
    }
}

@keyframes line-animate{
    from{
        height: 5px;
        width:20%;
    }
    to{
        height: 0.5px;
        width: 90%;
    }
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .loading-to-nav::before{
        position: absolute;
        left: 50%;
        bottom: 0%;
        
        content: "";
        width: 50%;
        height: 2px;
        
        background-color: var(--textColour);
        /* transform-origin: center 50%; */
        transform: translateX(-50%);
        animation: line-animate 1s cubic-bezier(0.6,0.01,0.22,0.93) 2s forwards;
    }

    @keyframes line-animate{
        from{
            height: 2px;
            width:50%;
        }
        to{
            height: 0.5px;
            width: 90%;
        }
    }

    .loading-to-nav>h1{
        font-family: 'Bodoni Moda', serif;
        /* font-family: 'Playfair Display', serif; */
        font-weight: 400;
        font-size: 1rem;
    
        margin: 0 0.5rem;
    
        /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    }
}