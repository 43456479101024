@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400;0,6..96,500;0,6..96,600;0,6..96,700;0,6..96,800;0,6..96,900;1,6..96,400;1,6..96,500;1,6..96,600;1,6..96,700;1,6..96,800;1,6..96,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');

/* @font-face{
  src: url(./fonts/BodoniModa-VariableFont.ttf);
  font-family: 'Bodono Moda';
} */

@font-face{
  src: url(./fonts/AspireDemibold.ttf);
  font-family: AspireDemibold;
}

/* @font-face{
  src: url(./fonts/CormorantGaramond-Light.ttf),
        url(./fonts/CormorantGaramond-Medium.ttf),
        url(./fonts/CormorantGaramond-Regular.ttf),
        ;
  font-family: 'Cormorant Garamond';
} */

/* @font-face{
  src: url(./fonts/WorkSans-VariableFont_wght.ttf),
  url(./fonts/WorkSans-Italic-VariableFont_wght.ttf);
  font-family: 'Work Sans';
} */

html {
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
  color: inherit;
}

video {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

:root{
  /* --templateBackground: #FAF9F6 #6D2737; */
  --templateBackground: #F0ECE3; 
  --templateBackgroundAlt: #191617;
  --templateContrast: #233d4d;

  /* --textColour: black; */
  --textColour: #6c031d;
  --textColorAlt: #191617;
  --textColorContrast:#588157;
  --textColourLink:#a19f97;

  --paperTextColour: #3f3f3f;
}
::-moz-selection { /* Code for Firefox */
  color: var(--templateBackground);
  background: var(--textColour);
}

::selection {
  color: var(--templateBackground);
  background: var(--textColour);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;

  /* -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;  */
}

/* //////Containers///////// */

.container{
  position: relative;
  
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* overflow-x: hidden; */

  background-color: var(--templateBackground);
  /* background-color: red; */
  color: var(--textColour);
}

.content-container{
  width: 100%;
  height: auto;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  color: var(--textColour);
}

.half-grid-container{
  width: 100%;
  height: auto;
  padding-bottom: 5rem;
  
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 2%;
  
  /* margin-top: 10rem; */
}

.alt-grid-container-section{
  padding-bottom: 0rem;
  /* border: 0.5px solid red; */
}

.section-grid-container{
  width: 100%;
  height: auto;
  padding: 1.5rem;
  margin-bottom: 5vh;

  display: grid;
  grid-template-columns: 50% 50%;

  /* border: 0.5px solid red; */
}


.half-vertical-grid-container{
  width: 100%;
  height: 50%;

  display: grid;
  grid-template-rows: 50% 50%;


  border: 0.5px solid red;
}

.full-row-container{
  position: relative;
  width: 100%;
  height: auto;

  grid-column: 1/ span 2;
  /* border: 0.5px solid red; */
}

.header-fill-container{
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* //////////Articles////////////// */
.left-article{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* ///////Sections//////////// */
.header-section{
  width: 100%;
  min-height: 10vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: 1rem;

  border-bottom: 0.1px solid var(--textColour);

  overflow: hidden; 
}

.header-section>h3{
  font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Bodoni Moda', serif; */
  font-size: 4rem;
  font-weight: 500;

  color: var(--textColour);

  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--textColour); */
  /* font-weight: 600; */
}

.foodmenu-header-section{
  width: 100%;
  min-height: 10vh;

  display: grid;
  grid-template-columns: 80% 20%;
  justify-content: flex-start;
  align-items: center;

  padding-left: 1rem;

  border-bottom: 0.1px solid var(--textColour);
  border-top: 0.1px solid var(--textColour);
  /* border: 0.1px solid var(--textColour); */

  overflow: hidden; 
}

.foodmenu-header-section>h3{
  font-size: 2.5rem;

  /* border: 1px solid red; */
}

.body-section{
  width: 100%;
  min-height: 10vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: 5rem;

  font-family: 'Cormorant Garamond', serif;
  font-size: 5rem;
  font-weight: 300;

  /* border: 0.5px solid black; */

  /* overflow-y: hidden; */
}
.body-section>div{
  width: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  /* border: 1px solid red; */
  overflow: hidden;
}

.body-section>div>span{
  background-color: var(--templateBackground);
}

.body-section>div>h3{
  padding-bottom: 1.5rem;
}

.body-section>h3,.body-section>p, .body-section>h3>span:nth-child(even),
.body-section>div>h3{
  font-family: 'Cormorant Garamond', serif;
  font-size: 2rem;
  font-weight: 300;
}

.body-section>h3>span:nth-child(odd), .body-section>span,
.body-section>div>span{
  margin-right: 0.5rem;

  font-family: 'Cormorant Garamond', serif;
  font-size: 6rem;
  font-weight: lighter;

  color: var(--textColorAlt);
}

.medium-text-section{
  width: 80%;
  height: 100%;

  padding-top: 5rem;
  /* margin-left: 2rem; */

  text-align: left;

  /* border: 0.5px solid var(--textColour); */
  
}

.description-text-section{
  padding-top: 0;
  margin-bottom: 10vh;
}

.medium-text-section>p{
  /* font-family: 'Cormorant Garamond', serif; */
  font-size: 1rem;
  font-weight: 400;
}

.medium-text-section>h3{
  font-family: 'Cormorant Garamond', serif;
  font-size: 5rem;

  font-weight: 400;
}

.description-text-section>p{
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.5rem;

  font-weight: 400;
}
/* .medium-text-section>h3>span{
  font-family: 'Work Sans', sans-serif;
  font-style: italic;
  font-weight:100;
  margin-right: 1rem;
} */

.left-medium-text{
  /* margin-left: 2rem; */
  padding-top: 0rem;
  padding-left: 3rem;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* border: 0.5px solid red; */
}

.left-medium-text>p{
  padding-left: 2rem;
}

.right-medium-text{
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  justify-self: flex-end;
  
  padding-top: 0rem;
  padding-right: 3rem;

  text-align: right;

  /* border: 0.5px solid red; */
}

.right-medium-text>p{
  padding-right: 2rem;
  text-align: right;
}

.right-medium-text>h3{
  margin-bottom: 2rem;
}

.banner-section{
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* border: 0.5px solid red; */
}

.banner-heading{
  position: relative;
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  /* border: 1px solid red; */
}

.banner-heading>h1{
  position: relative;
  font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Bodoni Moda', serif; */
  font-size: 10rem;
  font-weight: 300;

  text-align: center;
  
  color: var(--textColour);
}

.banner-heading-no-underline>h1{
  position: relative;
  font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Bodoni Moda', serif; */
  font-size: 6rem;
  font-weight: 300;
  
  color: var(--textColour);
}

.banner-heading>h1:before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);

  width: 70%;
  height: 1px;

  background-color: var(--textColour);
}

.banner-heading-no-underline>h1::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);

  width: 70%;
  height: 1px;

  background-color: var(--textColour);
}


.banner-heading>p{
  /* border: 1px solid red; */
  position: relative;
  font-size: 1.2rem;
  margin: 2rem 0;
  text-align: center;
}
.banner-heading>p:before{
  content: "";
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translate(-50%,0);

  width: 70%;
  height: 1px;

  background-color: var(--textColour);
}

.banner-heading-no-underline>p:before{
  content: "";
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translate(-50%,0);

  width: 0%;
  height: 0px;

  background-color: var(--textColour);
}

.banner-heading>p:last-child{
  margin-bottom: 20vh;
}

/* //////////Images//////// */
.full-img-container{
  position: relative;
  width: 100%;
  height: 75vh;

  /* margin: 0.2rem 0; */

  /* border: 2px solid var(--templateBackground); */
  /* outline: 10px solid var(--templateBackground);
  outline-offset: -9px; */
}

.section-img-container{
  position: relative;
  width: 95%;
  height: 70vh;

  display: flex;
  justify-content: center;

  vertical-align: middle;

  transition: 0.3s ease-in-out;

  cursor: pointer;

  /* border: 0.5px solid var(--textColour); */
}



.right-img-container{
  position: relative;
  width: 100%;
  height: 70vh;
  
  align-self: flex-end;
}

.left-img-container{
  position: relative;
  width: 100%;
  height: 70vh;
  
  align-self: flex-start;
}

.section-img-text{
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  transition: 0.3s ease-in-out;

  text-align: center;
  color: transparent;

  font-family: 'Bodoni Moda', serif;
  font-size: 5rem;

  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: white;

  opacity: 0;

  z-index: 3;

  transition: 0.6s ease-in-out;
}

.section-img{
  width: 100%;
  height: 100%;

  vertical-align: middle;

  object-fit: cover;
  filter: brightness(100%);

  transition: 0.3s ease-in-out;
}

.section-img-container:hover .section-img{
  filter: brightness(30%);
}

.section-img-container:hover .section-img-text{
  opacity: 1;
  filter: brightness(100%);
}

.full-img-container>img,.right-img-container>img,.left-img-container>img{
  
  width: 100%;
  height: 100%;

  /* display: block; */

  vertical-align: middle;

  object-fit: cover;
}

.big-text-container{
  position: relative;
  width: 100%;
  /* bottom: -12rem; */
  /* color: var(--textColour); */
  color: var(--templateBackgroundAlt);

  overflow-y: hidden;
  /* border: 1px solid blue; */
}

.left-img-text{
  left: 0;
}

.right-img-text{
  right:0;
  padding-right: 1rem;
}

.big-text-container>h1{
  padding-left: 1rem;
  /* font-family: 'Cormorant Garamond', serif; */
  font-family: 'Cormorant Garamond', serif;
  /* font-weight:lighter; */
  font-weight: lighter;
  font-size: 7rem;
}

.big-text-container>p{
  padding-left: 2.5rem;
  font-size: 1.8rem;
  font-weight: 200;
}

.blur-img{
  outline: 10px solid var(--templateBackground);
  outline-offset: -9px;
  /* filter: grayscale(100%); */
}

.gallery-img-container{
  position: relative;
  width: 100%;
  min-height: 70vh;

  /* border: 2px solid red; */

  grid-column: 1/span 2;

  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: auto min-content auto;
  gap: 2rem;

  overflow-x: hidden;
}

.gallery-img-box{
  position: relative;
  min-width: 60vw;
  width: 100%;
  height: 100%;

  object-fit: cover;

  /* border: 1px solid green; */
}

.gallery-img{
  width: 100%;
  height: 70vh;

  display: block;

  object-fit: contain;
}


/* ///////Buttons///////// */

.hover-underline-animation-btn {
  display: inline-block;
  position: relative;
  font-size: 1.1rem;
  color: var(--textColour);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  /* border: 0.5px solid red; */
}

.hover-underline-animation-btn::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.5px;
  bottom: -2px;
  left: 0;
  background-color: var(--textColour);
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}

.hover-underline-animation-btn:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.img-btn{
  position: absolute;
  top: 9px;
  /* left: 9px; */

  width: 4rem;
  height: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  font-weight: bold;

  background-color: var(--templateBackground);

  cursor: pointer;

  transition: 0.2s ease-in-out;
  /* border: 1px solid red; */
}

.img-btn>p{
  font-size: 1rem;
}

.img-btn:hover{
  top: 15px;
  transition: 0.2s ease-in-out;
}

.left-img-btn{
  left: 9px;
}

.right-img-btn{
  right: 9px;
}

.whatsapp-chat-btn{
  font-size: 1rem;
  background: transparent;
  border: none;
  outline: none;

  color: var(--textColour);

  cursor: pointer;

  transition: 0.3s ease-in-out;
  /* border: 1px solid red; */
}

/* .whatsapp-chat-btn:hover{
  color: var(--templateBackgroundAlt);
  transition: 0.3s ease-in-out;
} */

.header-icon-container{
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  /* border: 0.5px solid red; */
}

.header-icon-box{
  position: relative;
  width: 4rem;
  height: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 1px solid green; */
  border-radius: 50%;

  cursor: pointer;
}

.header-icon{
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* padding: 0.5rem; */
  /* margin-left: 5rem; */
  font-size: 2rem;
  /* transition: 0.3s ease-in-out; */

  color: var(--textColour);
  /* color: var(--templateBackground); */
  color: white;
  /* border: 0.5px solid green; */
}

.header-icon-bg-img{
  width: 100%;
  height: 100%;

  /* animation: button-rotate 5s linear infinite; */
}
.header-icon-box:hover .header-icon-bg-img{
  animation: button-rotate 5s linear infinite;
}

.header-icon-bg-img:hover{
  animation: button-rotate 5s linear infinite;
}


@keyframes button-rotate{
  from{
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  /* 50%{
    transform: rotate(180deg);
  } */
  to{
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .gallery-controll-btn-container{
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(100%,0%);

  width: 30vw;
  height: 50vh;

  padding-left: 2rem;


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  z-index: 9;

  border: 0.5px solid red;
} */

.gallery-controll-btn{
  position: relative;  
  cursor: pointer;
  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;


  /* overflow: hidden; */

  /* border: 0.5px solid red; */
}

.gallery-decoration-circle{
  position: absolute;
  left: 50%;
  bottom: 0%;
  
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;

  opacity: 0;
  
  background-color: black;
  /* transform-origin: center 50%; */
  transform: translate(-50%,100%);
  transition: 0.4s cubic-bezier(.6,.01,.56,.94);
}

.gallery-controll-btn>h1{
  font-family: 'Cormorant Garamond', serif;
  font-size: 2.8rem;
  font-weight: 400;

  padding-top: 0.5rem;
  margin: 0;
  /* border: 1px solid var(--textColour); */
  color: rgba(109, 3, 29, 0.5);
  /* color: transparent; */
  cursor: pointer;

  transition: 0.3s cubic-bezier(.6,.01,.56,.94);
}

.gallery-controll-btn>h1:hover{
  color: var(--textColour);
}

.gallery-react-icon{
  padding: 0;
  margin: 0;

}

/* .gallery-controll-btn>h1::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5.5rem;
  height: 5.5rem;
  border: 0.5px solid rgba(109, 3, 29, 0.2);
  border-radius: 50%;
} */


/* .gallery-controll-btn:hover .gallery-decoration-circle{
  transform: translate(-50%,70%);
  opacity: 1;
  transition: 0.4s cubic-bezier(.6,.01,.56,.94);
} */

/*///////////////////////////////////////////////*/
/*/////////////////ScrollBar/////////////////////*/
/*///////////////////////////////////////////////*/
 
/* width */
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  /* background: #e24545; */
  background: transparent;
  background: var(--templateBackground);
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  background: var(--textColour);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #460213;
} 

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
  .full-img-container{
    position: relative;
    width: 100%;
    height: 50vh;

    margin-bottom: 10vh;
  }

  .big-text-container>h1{
    padding-left: 0rem;
    font-size: 4rem;
  }

  .body-section{
    padding-left: 1rem;
    font-weight: 300;
  
    /* border: 0.5px solid black; */
  }

  .body-section>h3,.body-section>p, .body-section>h3>span:nth-child(even),
  .body-section>div>h3{
    font-size: 1.5rem;
    font-weight: 500;

    padding-bottom: 0.7rem;
  }
  
  .body-section>h3>span:nth-child(odd), .body-section>span,
  .body-section>div>span{
    margin-right: 1rem;
  
    font-size: 4rem;
  }

  .medium-text-section{
    padding-left: 3rem;
  }
  .medium-text-section>p{
    font-size: 0.8rem;
  }
  .medium-text-section>h3{
    font-size: 4.5rem;
  }

  .section-img-text{
    font-size: 4rem;
  }
}

/* /////////////////////768///////////////////// */
@media only screen and (max-width: 768px ){

  .container{
    overflow-x: hidden;
  }
  .big-text-container>h1{
    padding-left: 0rem;
    font-size: 3rem;
  }

  .half-grid-container{
    display: grid;
    grid-template-columns: 100%;
    gap: 0rem;
  }

  .body-section{
    padding-left: 1rem;
    font-weight: 300;
  
    /* border: 0.5px solid black; */
  }

  .section-grid-container{
    grid-template-columns: 100%;
    /* padding: 0.5rem; */
  }
  
  .body-section>h3,.body-section>p, .body-section>h3>span:nth-child(even),
  .body-section>div>h3{
    font-size: 1.2rem;
    font-weight: 500;

    padding-bottom: 0.5rem;
  }
  
  .body-section>h3>span:nth-child(odd), .body-section>span,
  .body-section>div>span{
    margin-right: 0.5rem;
  
    font-size: 2.5rem;
  }

  .medium-text-section{
    padding-top: 1.5rem;
    padding-left: 1rem;

    justify-content: center;

    grid-column: 1/span 2;
  
    /* border: 0.5px solid var(--textColour); */
    
  }

  .medium-text-section>h3{
    font-size: 4rem;
    font-weight: 400;
  }

  .left-medium-text{
    /* margin-left: 2rem; */
    padding-top: 0rem;
    padding-left: 1rem;
    /* border: 0.5px solid red; */
  }
  
  .left-medium-text>p{
    padding-left: 0rem;
  }

  .right-medium-text{
    /* margin-left: 2rem; */
    padding-top: 2rem;
    padding-left: 1rem;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    justify-self: flex-start;
    /* border: 0.5px solid red; */
  }
  
  .right-medium-text>p{
    padding-left: 0rem;
    text-align: left;
  }

  .section-img-container{
    height: 50vh;
    grid-column: 1/span 2;
  }
  .section-img-text{
    font-size: 3rem;
  }

  .foodmenu-header-section{
    min-height: 8vh;
  }
  .foodmenu-header-section>h3{
    font-size: 1.8rem;
  }

  .header-icon{
    font-size: 1.2rem;
  }
  .header-icon-bg-img{
    width: 80%;
    height: 80%;
  }

  .gallery-img-container{
    width: 100%;
    min-height: 50vh;
    /* height: 50vh; */
    /* border: 1px solid red; */
    background-color: transparent;
    gap: 0.2rem;
  }

  .gallery-controll-btn{
    position: relative;  
    cursor: pointer;
    width: 2rem;
    height: 2rem;

    /* border: 0.5px solid blue; */
  }
  .gallery-controll-btn>h1{
    font-size: 1.5rem;
  }
  .gallery-img-box{
    height: inherit;
    width: inherit;
  }
  .gallery-img{
    min-height: 50vh;
    height: inherit;
    width: inherit;
  }

  .banner-heading>h1{
    font-size: 6rem;
    text-align: center;
  }
  .banner-heading>p{
    font-size: 0.8rem;
  }

  .banner-heading-no-underline>h1{
    position: relative;
    font-family: 'Cormorant Garamond', serif;
    /* font-family: 'Bodoni Moda', serif; */
    font-size: 4rem;
    font-weight: 300;
    
    color: var(--textColour);
  }
}