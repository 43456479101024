.form-container{
    position: relative;

    width: 100%;
    min-height: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 5vh 0;

    /* border: 0.5px solid red; */
}

.form-card-img {
    position: absolute;
    left: 30%;
    top: 0;
    height: 55vh;
    width: 35vw;
    /* aspect-ratio: 1/1; */

    /* transform: translate(-10%,10%); */
    filter: drop-shadow(-5px 1px 8px #9b968d8e);

    border: 1rem solid #ffffff;
    border-radius: 5px;

    /* animation: card-shuffle 0.6s ease-in-out 0s forwards; */
}

.form-card-img>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 5% 50%;

    border-radius: 5px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.alert-submit-box{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;

    /* border: 2px solid green; */
}

.form-grid{
    position: relative;

    display: grid;
    grid-template-columns: 50% 50%;
    width: 35vw;
    height: 55vh;

    border-radius: 7px;

    overflow: hidden;

    padding: 2rem;
    background-color: white;

    /* transform: translate(5%,2.5%); */

    filter: drop-shadow(-3px 6px 5px #6e475141);
    /* #cac8c5 */
}

.form-img{
    position: absolute;
    top: 0;
    left: 0;

    overflow: hidden;

    object-fit: cover;
    /* width: inherit; */
    /* height: auto; */
}

.form-img>img{
    width: inherit;
    height: inherit;
    object-fit: cover;
    opacity: 1;
}

.form-header{
    
    font-family: 'Tangerine', cursive;
    font-size: 3rem;
    color: var(--paperTextColour);

    text-decoration: underline 1px;
}

.form-group{
    padding: 0.5rem 0.5rem 0.5rem 0;
    position: relative;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* transition: 0.6s ease-in-out; */
    /* border: 2px solid blue; */
}

.form-group>label{
    font-size: 0.8rem;
}

.form-control{
    width: 100%;
    padding: 0.6rem 1rem;
    
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid var(--templateContrast);
    /* border-radius: 20px; */

    /* font-family: 'Albert Sans', sans-serif; */
    /* font-family: 'Playfair Display', serif; */
    font-family: 'Inter', sans-serif;

    color: black;
    font-size: 0.8rem;
    font-weight: 300;
}

.form-control:focus{
    /* content: :""; */
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    /* border: 1px solid var(--textColorAlt); */
    /* border-bottom: 1px solid rgb(192, 192, 192); */
    outline: none;
}
.faq-form-control:focus{
    background-color: var(--templateBackground);
}

.form-control:focus::placeholder{
    color: transparent;
}

.form-control::selection{
    color:var(--textColorAlt);
    background: rgb(141, 141, 141);
}

.form-group-textarea{
    grid-column: 1 / span 2;
}

.form-group-textarea>label{
    align-self: flex-start;
}

.form-control-textarea{
    width: 100%;
    text-align: justify;
    /* border-bottom: 1px solid rgba(167, 167, 167, 0.5); */
    resize: none;
}
/* c5c5c5 */
.fill-space{
    width: 100%;
    /* border: 1px solid blue; */
}

.error-alert{
    padding: 0.5rem 1rem;

    position: absolute;
    width: fit-content;
    height: fit-content;
    top:0.6rem;
    left: 0%;

    /* background-color: transparent;
    border: none;
    border-radius: 20px;
    outline: none; */

    font-family: AspireDemibold;
    font-size: 1.5rem;

    color: var(--textColor);
    /* cursor: pointer; */
    transform: rotate(-2deg);
    transition: 0.3s ease-in-out;
}

.success-alert{
    text-align: center;
    /* background-color:#118ab2; */
}

.faq-error-alert{
    border: 1px solid var(--textColorAlt);
    color: var(--textColorAlt);
    background-color: #f13343;
}

.faq-success-alert{
    border: 1px solid var(--textColorAlt);
    color: var(--textColorAlt);
    background-color: #15aadb;
}


.submit-btn{
    padding: 0.5rem 1rem;

    background-color: transparent;
    border: none;
    border-radius: 20px;
    outline: none;

    color: var(--paperTextColour);
    cursor: pointer;

    font-family: AspireDemibold;
    font-size: 2rem;
    transition: 0.3s ease-in-out;
}

.faq-submit-btn{
    outline: 1px solid var(--textColorAlt);
    background-color: var(--templateBackground);
    color: var(--textColorAlt);
}

.submit-btn:hover{
    color:  var(--textColor);
}
.faq-submit-btn:hover{
    background-color: var(--templateContrast);
}

.footer-form-container{
    width: 100%;
    min-height: 10vh;

    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border: 1px solid red; */
}

.footer-form-container>p{
    font-family: 'Cormorant Garamond';
    font-size: 2rem;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 786px ){
    .form-container{
        width: 100%;

        /* border: 1px solid red; */
    }

    .form-card-img {
        position: absolute;
        left: 20%;
        top: 0;
        height: 55vh;
        width: 65vw;
        /* aspect-ratio: 1/1; */
    
        /* transform: translate(-10%,10%); */
        filter: drop-shadow(-5px 1px 8px #9b968d8e);
    
        border: 10px solid #faf6f6;
        border-radius: 5px;
    
        /* animation: card-shuffle 0.6s ease-in-out 0s forwards; */
    }
    
    .form-card-img>img{
        position: fixed;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 5% 100%;
    
        border-radius: 1px;
    }

    .form-grid{
        width: 100%;
        min-height: 50vh;
        grid-template-columns: 100%;
        left: 15%;
        /* border: 0.5px solid red; */
        /* grid-template-rows: auto auto auto; */
    }

    .form-group{
        grid-column: 1/span 2;
    }

    .form-group>label{
        font-size: 0.5rem;
    }

    .form-control{
        font-size: 0.5rem;
    }

    /* .alert-submit-box{
        grid-column:1/ span 2,
    } */

    .fill-space{
        width: 100%;
        min-height: 10vh;
    }
    
    .error-alert{
        top:0rem;
        font-size: 1rem;
    }

    .submit-btn{
        font-size: 1.2rem;
    }

    .error-content{
        font-size: 0.7rem;
        margin-bottom:5vh;
    }

    .footer-form-container>p{
        padding: 0 1rem;
        font-size: 1.2rem;
    }
}