.gallery-grid{
  padding: 2px;
  grid-template-columns: 31% 31% 31%;
  gap: auto;

  /* border: 0.5px solid red; */
}

.gallery-grid-img-box{
  width: 100%;
  aspect-ratio: 1/1;
  /* outline: 0px solid var(--textColour); */
  
  /* transition: 0.3s ease-in-out; */
}

.gallery-grid-img{
  width: 100%;
  height: 100%;
  
  display: block;
  
  object-fit: cover;
  
  cursor: pointer;

}

.gallery-grid-img-box:hover{
  outline: 2px solid var(--textColour);

  /* transition: 0.3s ease-in-out; */
}

.gallery-social-container{
  width: 100%;
  min-height: 2rem;
  grid-column: 1/span 3;

  padding: 0.5rem 1.2rem 0;
  margin-bottom: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* border: 0.5px solid blue */
}

.gallery-social-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gallery-social-header>h3{
  color: var(--textColour);
  font-weight: 400;
}

.gallery-insta-icon{
  font-size: 2.5rem;
  margin-right: 0.5rem;
}

.gallery-close-container{
  width: 100%;
  min-height: 2rem;
  grid-column: 1/span 3;

  padding: 0.5rem 1rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  /* border: 0.5px solid blue; */
  
}

.gallery-close-container>h3, .gallery-social-container>h3{
  cursor: pointer;
  font-size: 2.5rem;
  color: rgba(109, 3, 29, 0.5);
  transition: 0.3s ease-in-out;
}

.gallery-close-container>h3:hover, .gallery-social-container>h3:hover{
  color: var(--textColour);
  transition: 0.3s ease-in-out;
}

.gallery-img-bg{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 6;
}

.from-left-animation{
    position: absolute;
    top: 0;
    right: -100%;
    
    
    width: 100%;
    height: 100%;
    transform: scale(0.5) translateX(-30%);
    filter: grayscale(1);
    opacity: 0.6;


    background-color: var(--templateBackground);

    /* animation: name duration timing-function delay iteration-count direction fill-mode;
    animation: from-left-anim 1s cubic-bezier(0.445,  0.050, 0.550, 0.950) both; */
}

.from-right-animation{
    position: absolute;
    top: 0;
    left: -100%;
    
    
    width: 100%;
    height: 100%;
    transform: scale(0.5) translateX(30%);
    filter: grayscale(1);
    opacity: 0.6;


    background-color: var(--templateBackground);
}

.left-gallery-img{
    height: 100%;
    object-fit: cover;
}

@keyframes from-left-anim{
    0%{
      right: 0;
      height: 100%;
      width: 0%;
    }
    50%{
      right: 0;
      height: 100%;
      width: 150%;
    }
    100%{
      right: 100%; 
      height: 100%; 
      width: 0%;
    }
  }

  /* /////////////////////768///////////////////// */
@media only screen and (max-width: 768px ){
  .gallery-social-container{
    padding: 0rem;
    /* ma */
  }

  .gallery-close-container>h3, .gallery-social-container>h3{
    cursor: pointer;
    font-size: 1.5rem;
  }  

  .gallery-social-header>h3{
    font-size: 0.7rem;
  }

  .gallery-insta-icon{
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .from-left-animation{

    transform: scale(0.5) translateX(-15%);
    }

    .from-right-animation{
        transform: scale(0.5) translateX(15%);
    }
}