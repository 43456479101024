.navbar-container{
    position: fixed;
    top: 0;

    width: 100%;
    min-height: clamp(80px, 10vh,10vh);
    height: 17vh;

    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;

    /* border: 0.5px solid black; */
    
    background-color: var(--templateBackground);
    color: var(--textColour);

    z-index: 10;
}

.brand-logo-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--templateBackground);
    
    padding: 0.2rem 0;
}



.brand-logo-container::before{
    position: absolute;
    left: 0%;
    bottom: 0;
    
    content: "";
    width: 90%;
    height: 0.5px;
    
    background-color: var(--textColour);
    transform-origin: center 50%;
    transform: translateX(5vw);
}

.brand-logo-container>h1{
    margin: 0 1rem;
    font-family: 'Bodoni Moda', serif;
    /* font-family: 'Playfair Display', serif; */
    font-weight: 600;
    font-size: 2rem;
    
    cursor: pointer;
    /* border: 0.2px solid black; */
}

.navbar-logo-img{
    width: 10vh;
    height: 10vh;
    cursor: pointer;
}


.decoration-circle{
    width: 0.5rem;
    height: 0.5rem;

    border-radius: 50%;

    background-color: var(--textColour);
}


.nav-btn-container{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7vh;
    
    padding: 0 7%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    font-size: 1.1rem;
    
    /* border: 0.2px solid black; */
    animation: nav-reveal 0.7s cubic-bezier(0.6,0.01,0.05,0.95) 0.2s both;
}


.nav-btn-container::before{
    position: absolute;
    left: 0%;
    bottom: 0;
    
    content: "";
    width: 90%;
    height: 0.5px;
    
    background-color: var(--textColour);
    transform-origin: center 50%;
    transform: translateX(5vw);
}

@keyframes nav-reveal{
    from{
        opacity: 0;
        transform: translateY(-100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

.brand-info-container{
    display: flex;

    justify-content: center;
    align-items: center;
}

.brand-info{
    font-family: 'Bodoni Moda', serif;
    font-size: 1rem;
    padding: 0.5rem;
}

.btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 0.5px solid red; */
}

.nav-btn{
    position: relative;

    margin: 1rem;
    padding: 0.5rem 0;

    cursor: pointer;
    overflow-y: hidden;
}

.nav-btn::before{
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.5px;
    bottom: 2px;
    left: 0;
    background-color: var(--textColour);
    transform-origin: center right;
    transition: transform 0.35s ease-out;
}

.nav-btn:hover::before{
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    height: 0.5px;
    bottom: 2px;
    left: 0;
    background-color: var(--textColour);
    transform-origin: center center;
    transition: transform 0.35s ease-out;
}

.nav-decoration-circle{
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 100%);

    width: 1rem;
    height: 1rem;

    border-radius: 50%;

    background-color: var(--textColour);
    transition: 0.3s ease-in-out;
}

/* .nav-btn:hover .nav-decoration-circle{
    transform: translate(-50%, 70%);
    transition: 0.3s ease-in-out;
} */

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
.brand-logo-container>h1{
    margin: 0 0.5rem;
    font-family: 'Bodoni Moda', serif;
    /* font-family: 'Playfair Display', serif; */
    font-weight: 400;
    font-size: 1rem;
}

.decoration-circle{
    width: 0.3rem;
    height: 0.3rem;
}

.nav-btn-container{    
    padding: 0 2rem;

    justify-content: center;
    align-items: center;
}

.nav-btn{
    font-size: 0.8rem;
    /* border: 0.5px solid red; */
}
}