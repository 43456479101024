.grid-half-container{
    position: relative;
    grid-template-columns: 50% 50%;
    gap: 0%;
    padding-bottom: 0;

    min-height: 30vh;
}

/* .grid-half-container::before{
    content: "";
    position: absolute;
    top:0;
    left: 50%;
    transform: translate(-50%,0);

    width: 1px;
    height: 100%;

    background-color: var(--textColour);
} */

.grid-full-box{
    position: relative;
    min-height: 120vh;

    grid-column: 1/ span 2;
    display: grid;
    grid-template-columns: 50% 50%;

    /* margin-top: 20vh; */

    background-color: var(--templateBackground);
    /* fl */
    justify-content: center;
    align-items: center;

    /* border: 1px solid red; */
}

.hall-colour-bg{
    position: relative;
    width: calc(100% + 1px);
    height: 100%;

    color: var(--templateBackground);
    /* background-color: var(--textColour); */

    background: linear-gradient(180deg, #D3CEC4 0%, rgba(240,236,227,1) 100%);

    padding: 1.5rem 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    transition: 0.6s ease-in-out;
}

.hall-colour-bg-hover{
    width: 50%;
    grid-column: 1/ span 2;
    animation: bg-animation 1s cubic-bezier(0.6,0.01,0.05,0.95) 0s both; 
    }

@keyframes bg-animation{
    0%{
        background: linear-gradient(180deg, #D3CEC4 0%, rgba(240,236,227,1) 100%);
    }
    /* 50%{
        background: linear-gradient(180deg, #b4afa4 0%, rgba(240,236,227,1) 100%);
    } */
    100%{
        /* background: var(--templateBackground) url("https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06260_MBGcFqJQpL.jpg") no-repeat fixed center;  */
        background: (180deg, #77746e 0%, rgba(240,236,227,1) 100%);
    }
}

.right-bg{
    align-items: flex-end;
}

.left-bg>h1:nth-child(2){
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg) translate(-100%,0);
    transform-origin: left top;
    
    padding-right: 10rem;
    padding-top: 1rem;
    
    color: #464646;
    width: max-content;
    /* border: 1px solid red; */
}

.left-bg>h2{
    font-family: 'Work Sans', sans-serif;
    font-size: 2rem;
    font-weight: 300;

    padding-left: 2rem;
    margin-bottom: 1rem;
    color: #464646;
}

.hall-colour-bg>h1{
    font-family: 'Work Sans', sans-serif;
    font-size: 4rem;
    font-weight: 300;
    /* border: 1px solid red; */
}

.hall-colour-bg>h1:first-child{
    font-style: italic;
    font-weight: 200;
    font-size: 5rem;
    color: var(--textColour);
}

/* .hall-colour-bg-hover>h1:first-child{
    font-style: italic;
    font-weight: 200;
    font-size: 5rem;
    color: var(--templateBackground);
} */

.right-bg>h1:nth-child(2){
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-90deg) translate(0%,-100%);
    transform-origin: right top;

    padding-right: 10rem;
    padding-bottom: 3rem;

    color: #464646;

    /* border: 1px solid red; */
}

.hall-card-text{
    position: relative;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.right-card-text{
    align-items: flex-start;
}

.hall-text{
    width: 25vw;
    height: auto;

    padding-right: 2rem;
    /* border: 1px solid green; */
}

.right-hall-text{
    padding-right: 0;
    padding-left: 2rem;
}


.hall-text>p{
    font-size: 1.1rem;
    color: var(--textColour);
    text-align: right;
    /* font-size: 2rem; */
}
.right-hall-text>p{
    text-align: left;
}

.hall-card-img{
    position: absolute;
    top: 0%;
    /* left: 40%;
    transform: translate(-50%,50%); */

    /* overflow: hidden; */
    width: 50vw;
    height: 60vh;
}



.left-card-img{
    left: 40%;
    transform: translate(-50%,50%);
}
.right-card-img{
    right: 40%;
    transform: translate(50%,50%);
}


.hall-card-img-text{
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    transition: 0.3s ease-in-out;
    
    text-align: center;
    color: transparent;
    
    font-family: 'Bodoni Moda', serif;
    font-size: 5rem;
    
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white;
    
    opacity: 0;
    
    z-index: 3;
    
    cursor: pointer;
    
    transition: 0.6s ease-in-out;
}
.card-img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateX(20px);
    filter: brightness(100%);

    cursor: pointer;
    transition: 0.6s ease-in-out;
}

.hall-card-img:hover .card-img{
    filter: brightness(30%);
    transform: translateX(20px);
    transition: 0.3s ease-in-out;
}

.hall-card-img:hover .hall-card-img-text{
    opacity: 1;
    filter: brightness(100%);
}


/* /////////////////////768///////////////////// */
/* @media only screen and (max-width: 1000px ){
} */

@media only screen and (max-width: 1000px ){
    .grid-half-container{
        position: relative;
        grid-template-columns: 100%;
    }
    .grid-half-container{
        position: relative;
        grid-template-columns: 100%;
    }
    .grid-full-box{
        position: relative;
        min-height: 100vh;
        grid-template-columns: 100%;
    
        margin-bottom: 5vh;
    }

    .hall-card-img{
        width: 60vw;
        height: 50vh;
    }

    .hall-card-img-text{
        font-size: 3rem;
    }

    .left-card-img{
        left: 60%;
        transform: translate(-50%,50%);
    }

    .hall-card-text{
        position: relative;
    
        width: 100%;
        height: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        text-align: left;
        /* border: 0.5px solid red; */
    }

    .hall-text{
        width: 100%;
        height: auto;

        padding-right: 0;
        padding-left: 2rem;
        padding-bottom: 2rem;
        /* border: 1px solid green; */
    }

    .hall-text>p{
        color: var(--textColour);
        text-align: left;
        font-size: 0.8rem;
        /* font-size: 2rem; */
    }

    .hall-colour-bg{
        position: relative;
    }

    .left-bg>h1:nth-child(2){
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(-90deg) translate(-100%,0);
        transform-origin: left top;
    
        padding-right: 10rem;
        padding-top: 0rem;
    
        color: #464646;

        width: max-content;
    
        /* border: 1px solid red; */
    }
}