.venue-card-img{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%,30%) scale(1);
    /* left: 40%;
    transform: translate(-50%,50%); */

    overflow: hidden;
    width: 50vw;
    height: 60vh;

    outline: 0px solid var(--textColour);

    transition: 0.3s cubic-bezier(0.6,0.01,0.05,0.95);
}

.venue-transparent-img{
    position: absolute;
    top: 0;
    left: 0;

    width: inherit;
    height: inherit;
    object-fit: cover;

    /* opacity: 0.5; */
    animation: bg-img-animation 1s cubic-bezier(0.6,0.01,0.05,0.95) 0s both; 

}

.venue-full-bg-img{
    width: 100%;
    height: 100%;

    object-fit: contain;

    /* border: 1px solid red; */
}
.venue-full-bg-img>img{
    width: inherit;
    height: inherit;

    /* opacity: 0.5; */
    animation: bg-img-animation 1s cubic-bezier(0.6,0.01,0.05,0.95) 0s both; 

    object-fit: cover;
}

.venue-img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;

    filter: brightness(100%);

    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.venue-card-img:hover{
    outline: 2px solid var(--textColour);
    outline-offset: 10px;
}

.venue-card-img:hover .venue-img{
    width: 102%;
    height: 102%;
    filter: brightness(30%);

    transition: 0.3s ease-in-out;
}

@keyframes bg-img-animation{
    0%{
        opacity: 0%;
    }
    /* 50%{
        background: linear-gradient(180deg, #b4afa4 0%, rgba(240,236,227,1) 100%);
    } */
    100%{
        opacity: 5%;
    }
}

.venue-card-img:hover .hall-card-img-text{
    opacity: 1;
    filter: brightness(100%);
}

.venue-text{
    width: 15vw;
    height: auto;

    padding-right: 2rem;
    /* border: 1px solid green; */
}

.venue-para-container{
    position: absolute;
    top: 0%;
    right: 2%;
    transform: translate(0%,50%);
}

.venue-para{
    max-width: 15vw;

    margin-left: 3vw;
    margin-bottom: 0.5rem;
    
    /* border: 1px solid red; */

    /* color: var(--templateBackground); */
    font-size: 1.1rem;
    color: var(--textColour);
}

/* /////////////////////768///////////////////// */
@media only screen and (max-width: 1200px ){
    .venue-card-img{
        position: absolute;
        top: 0%;
        left: 60%;
        transform: translate(-60%,18vh) scale(1);

        width: 60vw;
        height: 40vh;
    }

    .venue-para-container{
        /* width: 100%; */
        position: absolute;
        /* bottom: 0; */
        top: 60vh;
        right: 0%;
        transform: translate(0%,0%);

        /* border: 1px solid red; */
    }

    .venue-para{
        max-width: 100%;

        margin-left: 18vw;
        padding-right: 5vw;
        margin-bottom: 0.5rem;
        
        /* border: 1px solid red; */
    
        /* color: var(--templateBackground); */
        font-size: 0.8rem;
        color: var(--textColour);
    }
}