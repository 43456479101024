.footer-container{
    position: relative;
    width: 100%;
    min-height: 40vh;

    margin-top: 20vh;
    padding: 0;

    display: grid;
    grid-template-columns: 28% 38% auto;
    /* border: 1px solid black; */
}

.footer-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 80%;
    height: 1px;
    background-color: var(--textColour);
}

.footer-brand-logo-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
    padding-top: 1rem;
    padding-right: 4rem;
    
    /* border: 0.5px solid blue; */
    /* border: 0.5px solid red; */
}

.footer-logo-img{
    width: clamp(100px,8vw,8vw);
    height: clamp(100px,8vw,8vw);;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-width: 100px;
    aspect-ratio: 1/1; */
}

.footer-contact-container{
    /* display: flex;
    flex-direction: column; */

    display: grid;
    grid-template-columns: 50% 50%;

    justify-content: flex-start;
    align-items: flex-start;  
    
    padding: 2rem 3rem;

    /* border: 0.5px solid red; */
}

.footer-contact-container>div>h3{
    color: var(--textColourLink);
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.footer-contact-container>div>a, .footer-social-container>a{
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
}

.footer-contact-container>div>a:hover .link-content, .footer-social-container>a:hover .link-content{
    text-decoration: underline;
    transition: 0.3s ease-in-out;
}

.link-header{
    font-size: 1rem;
    font-weight: 500;
    margin-right: 1rem;
    color: var(--textColourLink);

    transition: 0.3s ease-in-out;
}

.link-content{
    font-size: 0.8rem;
    transition: 0.3s ease-in-out;
}

.footer-contact-container>a:hover .link-header, .footer-social-container>a:hover .link-header{
    color: #464541;
}

.footer-social-container{
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 2rem 2rem 2rem 4rem;

    /* border: 0.5px solid red; */
}

.footer-social-icon{
    margin-top: 0.1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.footer-social-icon:hover{
    /* font-size: 1.6rem; */
    transform: scale(1.1);
}

.facebook{
    font-size: 1.3rem;
}

.footer-right-reserved{
    display: flex;
    align-self: flex-end;
    height: 2rem;

    /* padding-left: 10vw; */
    grid-column: 1/span 3;

    font-size: 0.8rem;

    justify-content: center;

    /* border: 1px solid red; */
}

/* /////////////////////1000///////////////////// */
@media only screen and (max-width: 1000px ){
    .footer-contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        
        padding: 0.5rem 1rem;
    
        /* border: 0.5px solid blue; */
    }

    .footer-contact-container>div{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;

        margin-bottom: 0.8rem;
    }
    .footer-contact-container>div>h3{
        align-self: center;
        font-size: 1rem;
    }
    .link-header{
        font-size: 0.8rem;
        align-self: center;
        margin-right: 0;
    }
    .link-content{
        font-size: 0.7rem;
        align-self: center;
    }
}

/* /////////////////////768///////////////////// */
@media only screen and (max-width: 768px ){
    
    .footer-container{
        min-height: 20vh;
        height: auto;
        grid-template-columns: 100%;

    }

    .footer-brand-logo-container{
        align-items: center;
    
        padding-top: 1rem;
        padding-right: 0rem;
    
        /* border: 0.5px solid red; */
    }

    .footer-logo-img{
        padding: 0;
        margin: 0;
        width: 20vw;
        height: 20vw;
        min-width: 60px;
        min-height: 60px;
        /* aspect-ratio: 1/1; */
        /* border: 1px solid black; */
    }

    .footer-contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        
        padding: 0.5rem 1rem;
    
        /* border: 0.5px solid blue; */
    }
    .footer-contact-container>div{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;

        margin-bottom: 0.8rem;
    }
    .footer-contact-container>div>h3{
        align-self: center;
        font-size: 1rem;
    }
    .link-header{
        font-size: 0.8rem;
        align-self: center;
        margin-right: 0;
    }
    .link-content{
        font-size: 0.7rem;
        align-self: center;
    }

    .footer-social-container{
        width: 100%;
        /* display: flex;
        flex-direction: row; */
        
        display: grid;
        grid-template-columns:auto auto auto auto;
        justify-content: center;
        align-items: center;
    
        padding: 1rem;
    
        /* border: 0.5px solid red; */
    }

    .footer-social-container>a{
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        margin-left: 1rem;
        display: flex;

        justify-content: center;
        align-items: center;

        /* border: 0.5px solid blue; */
    }

    .footer-social-icon-box{
        width: 100%;
        /* border: 1px solid black; */

        grid-column:1/span 4 ;
        display: flex;
        justify-content: center;
    }

    .footer-social-icon{
        margin-top: 0rem;
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 1.2rem;
    }

    .footer-right-reserved{
        display: flex;
        align-self: center;
        height: 1rem;

        margin-bottom: 0.5rem;

    
        /* padding-left: 10vw; */
        /* border: 0.5px solid black; */
        grid-column: 1/span 1;
        font-size: 0.6rem;
    }
}