.banner-container{
    position: relative;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-self: flex-start;

    /* border: 1px solid black; */
}

.banner-container>h1{
    font-family: 'Cormorant Garamond', serif;
    /* font-family: 'Bodoni Moda', serif; */
    font-size: 6rem;
    font-weight: 500;
    
    color: var(--textColour);
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .banner-container>h1{
        font-family: 'Cormorant Garamond', serif;
        /* font-family: 'Bodoni Moda', serif; */
        font-size: 3rem;
        font-weight: 500;
        
        color: var(--textColour);
    }
}