.contact-grid-container{
    border-top: 1px solid var(--textColour);

    min-height: 15vh;

    display: grid;
    grid-template-columns: 50% 50%;
    /* border: 1px solid; */

    margin-bottom: 0;

    overflow-y: hidden;
}

.contact-venue-banner-container{
    width: 100%;
    height: 15vh;
    /* border: 1px solid red; */

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--textColourLink);
    font-size: 2rem;
    font-weight: 300;

}

.contact-link-header{
    font-size: 1.5rem;
}

.contact-link-content{
    font-size: 1rem;
    transition: 0.3s ease-in-out;

    display: flex;
    align-items: center;

    cursor: pointer;

    word-break: break-all;
    
    /* border: 1px solid red; */
}

.contact-content{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    
    word-break: break-all;

    /* border: 1px solid red; */
}

.link-tag{
    overflow-y: hidden;

    /* border: 1px solid blue; */

    cursor: default;
    word-break: break-all;

    /* width: min-content; */
    height: auto;
}


.link-tag:hover .contact-link-content{
    color: var(--templateBackgroundAlt);
    transition: 0.3s ease-in-out;
}

.contact-social-icon{
    /* border: 0.5px solid red; */
    width: 1.5rem;
    height: 1.5rem;

    margin-left: 0.5rem;
}

/* /////////////////////768///////////////////// */
@media only screen and (max-width: 768px ){
    .contact-grid-container{
        border-top: 1px solid var(--textColour);
    
        min-height: 15vh;
    
        display: grid;
        grid-template-columns: 35% 65%;
    }

    .contact-content{
        width: 100%;
        height: auto;
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        word-break: break-all;
        /* border: 1px solid red; */

        overflow: hidden;
    }

    .contact-link-header{
        font-size: 1rem;
    }

    .contact-link-content{
        font-size: 0.8rem;
        
        word-break: break-all;
    }
}